@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {  
  --header-logo-width: 160px;
  --header-logo-height: 40px;

  --header-logo-width-phone: 160px;
  --header-logo-height-phone: 40px;

  /* ----------------------------------------------------------
   --------------------------COLORS PALLET ----------------------
   -------------------------------------------------------------*/
  --theme-color-1: #e74f13;
  --theme-color-1-light: #fde9cf;
  --theme-color-2: #707173;
  --theme-color-3: #333;

  --red: #ff4757;
  --green: #00d16d;
  --yellow: #febb02;
  --blue: #1b80eb;
  --blue-light: #0bbeff;

  --theme-color-1-darker: #{darken(#e74f13, 10%)};

  --theme-color-1-rgb: #{hexToRGB(#e74f13)};
  --theme-color-2-rgb: #{hexToRGB(#707173)};
  --theme-color-3-rgb: #{hexToRGB(#333)};

  --red-rgb: #{hexToRGB(#ff4757)};
  --green-rgb: #{hexToRGB(#00d16d)};
  --yellow-rgb: #{hexToRGB(#febb02)};
  --blue-rgb: #{hexToRGB(#00d16d)};
  --blue-light-rgb: #{hexToRGB(#00d16d)};

  /* ----------------------------------------------------------
    --------------------------SET COLORS ------------------------
    -------------------------------------------------------------*/

  /*main colors*/
  --primary-color: var(--theme-color-1);
  --secondary-color: var(--theme-color-2);
  --tertiary-color: var(--theme-color-3);

  --primary-color-darker: var(--theme-color-1-darker);

  --primary-color-rgb: var(--theme-color-1-rgb);
  --secondary-color-rgb: var(--theme-color-2-rgb);
  --tertiary-color-rgb: var(--theme-color-3-rgb);

  // background colors (bc)
  --bc-primary: var(--primary-color);
  --bc-primary-light: var(--theme-color-1-light);
  --bc-secondary: var(--secondary-color);
  --bc-tertiary: var(--tertiary-color);


  --bc-primary-rgb: var(--primary-color-rgb);
  --bc-secondary-rgb: var(--secondary-color-rgb);
  --bc-tertiary-rgb: var(--tertiary-color-rgb);

  --primary-gradient: linear-gradient(210deg, var(--theme-color-1) 30%, var(--color-2) 100%);

  --text-primary: var(--primary-color);

  --footer-header-bc: #333;
}
